(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbOrder.directive:wbOrderToolbar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="wbOrder">
   <file name="index.html">
   <wb-order-toolbar></wb-order-toolbar>
   </file>
   </example>
   *
   */
  angular
    .module('wbOrder')
    .directive('wbOrderToolbar', wbOrderToolbar);

  function wbOrderToolbar() {
    return {
      restrict: 'E',
      templateUrl: 'wb-order/directives/wb-order-toolbar-directive.tpl.html',
      replace: false,
      controllerAs: 'wbOrderToolbar',
      controller: function ($mdDialog, $document, LoggedUser, _, OrderService) {
        var vm = this
          , isAuthenticated = LoggedUser.isAuthenticated()
          , steps = []
          , beforeYouBeginStep = {
            name: 'order.steps.beforeYouBegin',
            icon: 'card'
          }
          , orderTypeStep = {
            name: 'order.steps.orderType',
            icon: 'arrow-split'
          }
          , cardDataStep = {
            name: 'order.steps.cardData',
            icon: 'card'
          }
          , billingDataStep = {
            name: 'order.steps.billingData',
            icon: 'file-text'
          }
          , sendOrderStep = {
            name: 'order.steps.sendOrder',
            icon: 'assignment-check'
          };

        vm.getActiveStateIndex = function getActiveStateIndex() {
          return OrderService.getActiveStateIndex();
        };

        if (isAuthenticated) {
          steps.push(orderTypeStep, cardDataStep, sendOrderStep);
        } else {
          steps.push(beforeYouBeginStep, orderTypeStep, cardDataStep, billingDataStep, sendOrderStep);
        }

        vm.isAuthenticated = isAuthenticated;
        vm.steps = steps;

        vm.openFaqDialog = function openFaqDialog() {
          $mdDialog.show({
            controller: 'FaqDialogCtrl',
            controllerAs: 'faqDialog',
            templateUrl: 'wb-faq/views/faq-dialog.tpl.html',
            parent: angular.element($document.body),
            clickOutsideToClose: true,
            focusOnOpen: false
          });
        };
      }
    };
  }
}());
